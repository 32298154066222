import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Colors } from '../styles/globalStyles';

interface IChip {
  to: string;
  title: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const ChipLink = styled(Link)`
  margin: 2px 0;
  border-radius: 32px;
  border: 1px solid ${Colors.grayDark};
  padding: 8px 12px;
  background: #eee;
  font-size: 12px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: ${Colors.black};
  &:hover {
    background: ${Colors.grayLight};
  }

  span {
    display: block;
    padding: 5px 0;
    margin-left: 2px;
    margin-right: 2px;
  }
`;

const Chip = ({ prefix, title, suffix, to }: IChip) => {
  return (
    <ChipLink className="escape" to={to}>
      {prefix ? <span>{prefix}</span> : null}
      <span>{title}</span>
      {suffix ? <span>({suffix})</span> : null}
    </ChipLink>
  );
};

export default Chip;
