import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import {
  HiOutlineArrowLeft,
  HiOutlineArrowRight,
  HiOutlineArchive,
} from 'react-icons/hi';
import { Colors } from '../styles/globalStyles';

const PrevNextNav = styled.nav`
  background: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  gap: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-image: linear-gradient(to top, black 50%, white 50%);
  @media (min-width: 680px) {
    flex-direction: row;
    background-image: linear-gradient(to left, black 50%, white 50%);
  }

  div {
    width: 100%;
    @media (min-width: 680px) {
      padding: 30px;
      width: 50%;
    }

    a {
      text-decoration: none;
      color: ${Colors.red};
      &:hover {
        text-decoration: underline;
      }
    }

    small {
    }

    h4 {
      margin: 0;
    }

    p {
      margin: 0;
      margin-bottom: 15px;
    }
  }
`;

const BeginNav = styled.div`
  @media (min-width: 680px) {
    text-align: left;
  }
`;
const EndNav = styled.div`
  @media (min-width: 680px) {
    text-align: right;
  }
`;

const DefaultPrevious = () => {
  return (
    <section>
      <Link to="/blog">
        <p>
          <HiOutlineArchive />
        </p>
        <small>No previous post</small>
        <h4>This is ground zero. Back to blog archive. </h4>
      </Link>
    </section>
  );
};
const DefaultNext = () => {
  return (
    <section>
      <Link to="/blog">
        <p>
          <HiOutlineArchive />
        </p>
        <small>No next post</small>
        <h4>See all posts in archive</h4>
      </Link>
    </section>
  );
};

const PrevNext = ({ previous, next }) => {
  return (
    <PrevNextNav>
      <BeginNav>
        {previous?.slug ? (
          <Link to={previous.slug}>
            <p>
              <HiOutlineArrowLeft />
            </p>
            <small>Previous</small>
            <h4>{previous.title}</h4>
          </Link>
        ) : (
          <DefaultPrevious />
        )}
      </BeginNav>
      <EndNav>
        {next?.slug ? (
          <Link to={next.slug}>
            <p>
              <HiOutlineArrowRight />
            </p>
            <small>Next</small>
            <h4>{next.title}</h4>
          </Link>
        ) : (
          <DefaultNext />
        )}
      </EndNav>
    </PrevNextNav>
  );
};

export default PrevNext;
