import React from 'react';
import styled from '@emotion/styled';
import { Colors } from '../styles/globalStyles';

const TimeToReadWrapper = styled.p`
  color: ${Colors.grayDark};
  span {
    margin-right: 2px;
  }
`;

const TimeToRead = ({ timeToRead }) => {
  const pluralize = (timeToRead) => (timeToRead > 1 ? 'mins' : 'min');
  let emoji = '🚀';
  if (timeToRead > 2 && timeToRead <= 4) {
    emoji = '🛫';
  } else if (timeToRead > 4 && timeToRead < 6) {
    emoji = '🚌';
  } else if (timeToRead > 6 && timeToRead < 8) {
    emoji = '🚂';
  } else if (timeToRead > 8) {
    emoji = '🚶';
  }
  return (
    <TimeToReadWrapper>
      <span role="presentation">{emoji}</span>
      <span>{`${timeToRead} ${pluralize(timeToRead)} to read`}</span>
    </TimeToReadWrapper>
  );
};

export default TimeToRead;
