import React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import {
  MinPageHeight,
  BlogPostGrid,
  Container,
  Colors,
} from '../styles/globalStyles';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from '@emotion/styled';
import PrevNext from '../components/PrevNext';
import PostTags from '../components/PostTags';
import TimeToRead from '../components/TimeToRead';
import Button from '../components/button';
import { BiArrowBack } from 'react-icons/bi';

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import 'react-medium-image-zoom/dist/styles.css';

deckDeckGoHighlightElement();

const PostSubtitle = styled.section`
  display: grid;
  grid-template-rows: 1fr;
  margin-top: 30px;
  color: ${Colors.grayDark};
  @media (min-width: 768px) {
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
  }
  justify-content: flex-start;
  align-items: baseline;
  > p {
    margin: 0;
  }
`;

const BlogHeaderNav = styled.nav`
  display: flex;
  margin-top: 1rem;
`;

export default function Template({ pageContext }) {
  const { postData, previous, next, tags } = pageContext;
  const { title, date } = postData.frontmatter;
  const { timeToRead } = postData;
  return (
    <Layout>
      <Seo
        title={`${postData.frontmatter.title} Vinay Raghu - Software Developer`}
        keywords={[`gatsby`, `application`, `react`]}
      />
      <MinPageHeight>
        <Container>
          <BlogPostGrid>
            <BlogHeaderNav>
              <Button to="/blog">
                <BiArrowBack style={{ marginRight: '2px' }} />
                Back to blog
              </Button>
            </BlogHeaderNav>
            <h1>{title}</h1>
            <PostSubtitle>
              <p>Published on {date}</p>
              <TimeToRead timeToRead={timeToRead} />
            </PostSubtitle>
            <PostTags tags={tags} />
            <main className="post-body">
              <MDXRenderer>{postData.body}</MDXRenderer>
            </main>
            <PrevNext previous={previous} next={next} />
          </BlogPostGrid>
        </Container>
      </MinPageHeight>
    </Layout>
  );
}
