import React from 'react';
import styled from '@emotion/styled';
import Chip from './Chip';
// import { Colors } from '../styles/globalStyles';

interface ITag {
  title: string;
  url: string;
  count?: number;
}

interface IPostTags {
  tags: Array<ITag>;
}

const PostTagsList = styled.ul`
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
  gap: 8px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  list-style: none;
  padding-left: 0;
`;

const PostTagsListItem = styled.li``;

const PostTags = ({ tags }: IPostTags) => {
  return (
    <PostTagsList className="escape">
      {tags.map((tag, index) => {
        return (
          <PostTagsListItem key={index}>
            {tag.count ? (
              <Chip title={tag.title} to={tag.url} suffix={tag.count} />
            ) : (
              <Chip title={tag.title} to={tag.url} />
            )}
          </PostTagsListItem>
        );
      })}
    </PostTagsList>
  );
};

export default PostTags;
