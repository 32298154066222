import React from 'react';
import styled from '@emotion/styled';
import { Colors } from '../styles/globalStyles';
import { Link } from 'gatsby';

const StyledButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  border: 1px solid ${Colors.grayLight};
  border-radius: 40px;
  text-decoration: none;
  color: ${Colors.black};  
  &:hover {
    background: ${Colors.grayLighter};
  }
`;

const Button = ({ to, children }) => {
  return (
    <StyledButton className="escape" to={to}>
      {children}
    </StyledButton>
  );
};

export default Button;
